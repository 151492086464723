import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import {
  useSessionTokenQuery,
  usePassResetMutation,
  useCurrentUserQuery,
} from "../../features/user/userApiSlice";
import toast from "react-hot-toast";
import { HeadProps, navigate } from "gatsby";
import { handleErrorResponse } from "../../app/apiSlice";
import ButtonPrimary from "../../components/ButtonPrimary";
import FormPageWrapper from "../../components/FormPageWrapper";
import Layout from "../../components/Layout";
import ButtonSpinner from "../../components/ButtonSpinner";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../../components/FormErrorMessage";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import Seo from "../../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

interface PasswordResetFormValues {
  pass: string;
}

const PasswordReset = () => {
  // Get the user-pass-reset token.
  const location = useLocation();
  const queryParams = parse(location.search);
  const passResetToken = queryParams["pass-reset-token"];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetFormValues>();

  const { data: currentUser } = useCurrentUserQuery();
  const { data: sessionToken } = useSessionTokenQuery();
  const [passResetTrigger, { isLoading }] = usePassResetMutation();
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);

  const onSubmit: SubmitHandler<PasswordResetFormValues> = (values) => {
    passResetTrigger({
      sessionToken,
      currentUserId: currentUser.data.uuid,
      passResetToken,
      pass: values.pass,
    })
      .unwrap()
      .then((fulfilled) => {
        toast.success("New password saved!");
        navigate("/user");
      })
      .catch((rejected) => {
        console.error(rejected);
        handleErrorResponse(rejected).forEach((m) => {
          const e = Error(m);
          e.name = "PasswordReset: onSubmit";
          Sentry.captureException(e);
        });
      });
  };

  return (
    <Layout>
      <FormPageWrapper>
        <h1 className="mb-8 text-5xl font-bold text-neutral-700 text-center">
          Please enter a new password
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <label
              htmlFor="pass"
              className="font-bold text-neutral-700 block mb-1"
            >
              New password
            </label>
            <div className="relative">
              <input
                className={`border shadow p-3 w-full rounded text-neutral-700 ${
                  errors.pass ? "border-red-300" : "border-neutral-300"
                }`}
                id="pass"
                placeholder="xxxxxxxx"
                type={isPasswordShowing ? "text" : "password"}
                {...register("pass", {
                  required: true,
                  maxLength: 512,
                  minLength: 6,
                })}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setIsPasswordShowing(!isPasswordShowing);
                  }}
                  type="button"
                >
                  {isPasswordShowing ? (
                    <BsEyeFill className="text-neutral-500 h-5 w-5" />
                  ) : (
                    <BsEyeSlashFill
                      aria-label="Your password is currently hidden. Select this to show your password."
                      className="text-neutral-500 h-5 w-5"
                    />
                  )}
                </button>
              </div>
            </div>
            {errors.pass?.type === "required" && (
              <FormErrorMessage>The new password is required.</FormErrorMessage>
            )}
            {errors.pass?.type === "minLength" && (
              <FormErrorMessage>
                Must be at least 6 characters.
              </FormErrorMessage>
            )}
            {errors.pass?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 512 characters.
              </FormErrorMessage>
            )}
          </div>
          <ButtonPrimary
            size="20"
            type="submit"
            disabled={!currentUser?.data?.uuid || !sessionToken || isLoading}
          >
            Submit
            <ButtonSpinner enabled={isLoading} />
          </ButtonPrimary>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default PasswordReset;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Password reset | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
